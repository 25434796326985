<template>
  <div class="cale">
    <el-dialog title="计费计算器" :visible.sync="dialogVisible" width="70%">
      <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
        <el-form-item label="计费规则">
          <el-select v-model="formInline.feeId" placeholder="请选择" @change="ruleChange">
            <el-option
              v-for="item in ruleArr"
              :key="item.feeId"
              :label="item.feeName"
              :value="item.feeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Entry_time')">
          <el-date-picker v-model="formInline.enterTime" type="datetime"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Appearance_time')">
          <el-date-picker v-model="formInline.leaveTime" type="datetime"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="cale()">计算</el-button>
        </el-form-item>
      </el-form>
      <div class="result">
        <div class="titleBox">
          <span class="bar"></span>
          <h3 class="txt">计算结果</h3>
        </div>
        <div class="screen">
          <p class="txt">
            收费总金额：
            <span class="money">{{money / 100}}</span> 元
          </p>
        </div>
      </div>
      <div class="chargeDetail">
        <div class="titleBox">
          <span class="bar"></span>
          <h3 class="txt">计费模型详情</h3>
        </div>
        <div class="detailsBox">
          <div class="lineBox">
            <p class="line">
              规则名称：{{feeName}}
              <span class="txt"></span>
            </p>
          </div>
          <div class="lineBox">
            <p class="line">
              收费标准：
              <span class="txt">
                <el-form
                  label-position="right"
                  label-width="120px"
                  class="b_form"
                  v-for="(rule, index) in source.rules"
                  :key="index"
                >
                  <el-form-item label="生效日期：" prop="description">
                    <span>{{rule.date}}</span>
                  </el-form-item>
                  <div v-for="(detail, index) in rule.details" :key="detail.cid">
                    <el-form-item :label="'时段' + (index +  1) +'：'">
                      <span>{{detail.start + '-' + detail.end}}</span>
                      <span v-if="detail.items[0].type != 1" class="feeDis">时段最高金额：{{detail.limit}}</span>
                    </el-form-item>
                    <el-form-item
                      :label="'区间' + (index +  1) + '：'"
                      prop="description"
                      v-for="(item, index) in detail.items"
                      :key="item.cid"
                    >
                      <!-- <span>{{item.segment}}分钟</span> -->
                      <span
                        class="feeDis"
                      >计费方式： {{item.type == 2 ? '周期' : item.type == 0 ? '基础时段' : '次'}}</span>
                      <span class="feeDis">{{item.segment}}分钟</span>
                      <span
                        class="feeDis"
                      >{{item.unit}}元/{{item.type == 1 ? '次': item.period + '分钟'}}</span>
                    </el-form-item>
                  </div>
                </el-form>
              </span>
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public";
export default {
  data() {
    let today = new Date();
    let yesterday = new Date(new Date().getTime() - 86400000);
    return {
      dialogVisible: false,
      parkId: "",
      money: "",
      ruleArr: [],
      formInline: {
        feeId: "",
        enterTime: yesterday,
        leaveTime: today
      },
      statusList: [],
      feeName: "",
      source: []
    };
  },
  methods: {
    ruleChange(val) {
      this.$axios.get("/acb/2.0/fee/byId/" + val).then(res => {
        if (res.state == 0) {
          this.feeName = res.value.feeName;
          this.source = JSON.parse(res.value.property);
          this.isBind = res.value.isBind;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    cale() {
      let entryDate = dateFormat(
        this.formInline.enterTime,
        "yyyy-MM-dd HH:mm:ss"
      );
      let exitDate = dateFormat(
        this.formInline.leaveTime,
        "yyyy-MM-dd HH:mm:ss"
      );
      this.$axios
        .get("/acb/2.0/customerComplaint/charge", {
          data: {
            feeId: this.formInline.feeId,
            entryDate,
            exitDate
          }
        })
        .then(res => {
          this.money = res.value;
        });
    },
    close() {},
    getRule() {
      this.$axios
        .get("/acb/2.0/fee/list", {
          data: {
            page: 1,
            pageSize: 9999
          }
        })
        .then(res => {
          this.ruleArr = res.value.list;
          this.formInline.feeId = res.value.list[0].feeId;
          this.ruleChange(this.formInline.feeId)
        });
    }
  },

  created() {
    this.getRule();
  }
};
</script>
<style lang="stylus" scoped>
.cale {
  .titleBox {
    .bar {
      font-size: 22px;
      width: 3px;
      height: 14px;
      display: block;
      background: #3F9EFF;
      float: left;
      margin-right: 5px;
      margin-top: 4px;
    }

    .txt {
      font-size: 22px;
    }
  }

  .result {
    margin-bottom: 30px;

    .screen {
      text-align: center;
      line-height: 100px;

      .txt {
        font-size: 22px;
        color: #667385;
      }

      .money {
        font-size: 30px;
        line-height: 30px;
        color: #3F9EFF;
      }
    }
  }

  .detailsBox {
    .lineBox {
      padding: 20px 0;

      .line {
        font-size: 18px;
        color: #667385;

        .txt {
          color: #333;
          font-size: 18px;
        }
      }
    }
  }
}

>>>.el-dialog__header {
  padding: 10px 20px;
  background: #324057;

  span {
    color: #fff;
  }

  .el-dialog__headerbtn {
    top: 10px;
    right: 10px;

    .el-dialog__close {
      font-size: 24px;
    }
  }
}

>>>.el-dialog {
  margin-top: 10vh !important;
}

>>>.el-dialog__body {
  padding-top: 15px;
}
</style>
