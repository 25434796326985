var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cale" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "计费计算器",
            visible: _vm.dialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "label-position": "right",
                model: _vm.formInline,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "计费规则" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.ruleChange },
                      model: {
                        value: _vm.formInline.feeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "feeId", $$v)
                        },
                        expression: "formInline.feeId",
                      },
                    },
                    _vm._l(_vm.ruleArr, function (item) {
                      return _c("el-option", {
                        key: item.feeId,
                        attrs: { label: item.feeName, value: item.feeId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.Entry_time") } },
                [
                  _c("el-date-picker", {
                    attrs: { type: "datetime" },
                    model: {
                      value: _vm.formInline.enterTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "enterTime", $$v)
                      },
                      expression: "formInline.enterTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.Appearance_time") } },
                [
                  _c("el-date-picker", {
                    attrs: { type: "datetime" },
                    model: {
                      value: _vm.formInline.leaveTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "leaveTime", $$v)
                      },
                      expression: "formInline.leaveTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.cale()
                        },
                      },
                    },
                    [_vm._v("计算")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "result" }, [
            _c("div", { staticClass: "titleBox" }, [
              _c("span", { staticClass: "bar" }),
              _c("h3", { staticClass: "txt" }, [_vm._v("计算结果")]),
            ]),
            _c("div", { staticClass: "screen" }, [
              _c("p", { staticClass: "txt" }, [
                _vm._v(" 收费总金额： "),
                _c("span", { staticClass: "money" }, [
                  _vm._v(_vm._s(_vm.money / 100)),
                ]),
                _vm._v(" 元 "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "chargeDetail" }, [
            _c("div", { staticClass: "titleBox" }, [
              _c("span", { staticClass: "bar" }),
              _c("h3", { staticClass: "txt" }, [_vm._v("计费模型详情")]),
            ]),
            _c("div", { staticClass: "detailsBox" }, [
              _c("div", { staticClass: "lineBox" }, [
                _c("p", { staticClass: "line" }, [
                  _vm._v(" 规则名称：" + _vm._s(_vm.feeName) + " "),
                  _c("span", { staticClass: "txt" }),
                ]),
              ]),
              _c("div", { staticClass: "lineBox" }, [
                _c("p", { staticClass: "line" }, [
                  _vm._v(" 收费标准： "),
                  _c(
                    "span",
                    { staticClass: "txt" },
                    _vm._l(_vm.source.rules, function (rule, index) {
                      return _c(
                        "el-form",
                        {
                          key: index,
                          staticClass: "b_form",
                          attrs: {
                            "label-position": "right",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生效日期：",
                                prop: "description",
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(rule.date))])]
                          ),
                          _vm._l(rule.details, function (detail, index) {
                            return _c(
                              "div",
                              { key: detail.cid },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "时段" + (index + 1) + "：",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(detail.start + "-" + detail.end)
                                      ),
                                    ]),
                                    detail.items[0].type != 1
                                      ? _c("span", { staticClass: "feeDis" }, [
                                          _vm._v(
                                            "时段最高金额：" +
                                              _vm._s(detail.limit)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._l(detail.items, function (item, index) {
                                  return _c(
                                    "el-form-item",
                                    {
                                      key: item.cid,
                                      attrs: {
                                        label: "区间" + (index + 1) + "：",
                                        prop: "description",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "feeDis" }, [
                                        _vm._v(
                                          "计费方式： " +
                                            _vm._s(
                                              item.type == 2
                                                ? "周期"
                                                : item.type == 0
                                                ? "基础时段"
                                                : "次"
                                            )
                                        ),
                                      ]),
                                      _c("span", { staticClass: "feeDis" }, [
                                        _vm._v(_vm._s(item.segment) + "分钟"),
                                      ]),
                                      _c("span", { staticClass: "feeDis" }, [
                                        _vm._v(
                                          _vm._s(item.unit) +
                                            "元/" +
                                            _vm._s(
                                              item.type == 1
                                                ? "次"
                                                : item.period + "分钟"
                                            )
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }